import React, { useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Container } from '@mui/material';
import Header from '../Header'
import axios from 'axios'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { BASE_URL } from '../../baseURl'
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import moment from 'moment'




export default function App() {
  const [rowSelection, setRowSelection] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([])
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [editId, setEditId] = useState('')
  const [date, setDate] = useState('')
  const [open, setOpen] = useState(false)
  const [category, setCategory] = useState('');
  const [value2, setValue2] = React.useState(new Date());
  const [value1, setValue1] = React.useState(new Date());
  const [dob, setDOB] = useState(new Date());
  const [dod, setDOD] = useState(new Date());

  const handleOpen = (row) => {
    console.log(row)
    setEditId(row.id)
    setQuestion(row.question)
    setAnswer(row.answer)
    setCategory(row.category )
    setValue1(row.dod ?? new Date())
    setValue2(row.dob ?? new Date())
    setDate(row.date)
    setOpen(true)
  }

  const [value, setValue] = React.useState(new Date());
  const [dates, setDates] = useState(new Date());


  const handleChange = (newValue) => {
    setValue(newValue)
    setDates(moment(newValue).format('YYYY-MM-DD'));
  };
  const handleClose = () => setOpen(false);


  useEffect(() => {
    // let today = ;
    setDates(moment(new Date()).format('YYYY-MM-DD'));
    
  // (today)
  }, [])
console.log("date",value)
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: ' 25px',
    boxShadow: 24,
    p: 4,
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.question,
        accessorKey: 'Question', //simple recommended way to define a column
        header: 'Question',
        muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
      },
      {
        accessorFn: (row) => row.answer, //alternate way
        id: 'Answer', //id required if you use accessorFn instead of accessorKey
        header: 'Answer',
        Header: <i style={{ color: 'green' }}>Age</i>, //optional custom markup
      },
      {
        accessorFn: (row) => row.date, //alternate way
        id: 'Date', //id required if you use accessorFn instead of accessorKey
        header: 'Date',
        Header: <i style={{ color: 'green' }}>Date</i>, //optional custom markup
      },
    ],
    [],
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const Getdata = () => {
    axios.get(`${BASE_URL}/question`).then((response) => {
     // console.log("response", response)
      setData(response.data)
    }).catch((error) => {
      NotificationManager.success('Internet Server Issue')
    })
  }

  const HandleSumbitEdit = (e) => {
    // e.preventDefult()
    const data = {
      question,
      answer,
      date,
      category,
      dod:value1,
      dob:value2 
    }
    console.log("responseupdate", data);
    axios.patch(`${BASE_URL}/question/${editId}`, data).then((response) => {
    //  console.log("response", response)
      NotificationManager.success('Admin Edit Info Successfully')
      setQuestion('')
      setAnswer('')
      Getdata()
      handleClose()
    }).catch((error) => {
      NotificationManager.error('Internet Server Issue')
    })
  }

  const handleDelete = (id) => {

    axios.delete(`${BASE_URL}/question/${id}`, data).then((response) => {
      //console.log("response", response)
      NotificationManager.success('Admin Delete Row Successfully')
      Getdata()
    }).catch((error) => {
      NotificationManager.error('Internet Server Issue')
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    Getdata()
  }, []);
  useEffect(() => {
    //do something when the row selection changes
  }, [rowSelection]);

  const handleChangebirth = (newValue) => {
    setValue2(newValue)
    setDOB(moment(newValue).format('YYYY-MM-DD'))
  }
  
  const handleChangedead = (newValue) => {
    setValue1(newValue)
    setDOD(moment(newValue).format('YYYY-MM-DD'))
  };


  const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
    return <input {...props} defaultValue={defaultValue} ref={inputRef} />;
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          '& > :not(style)': { m: 1,mt:2 },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>

            <MobileDatePicker
              label="Select date"
              inputFormat="MM-dd-yyyy"
              value={value}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />

          </Stack>
        </LocalizationProvider>
      </Box>
      <Container maxWidth="x
      l" >

        <div className='container'>
          <div className='row'>

            <div className='mt-4' style={{ marginTop: '40px' }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Question</TableCell>
                      <TableCell align="right">Answer</TableCell>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Category</TableCell>
            <TableCell align="right"> Date of Birth
</TableCell>
            <TableCell align="right">Date of Death
</TableCell>
                      <TableCell align="right">Correct</TableCell>
                      <TableCell align="right">Incorrect</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.filter((val) => {
                      if (dates === '') {
                        return val
                      } else if (val?.date?.toLowerCase().includes(dates?.toLowerCase())) {
                        return val
                      }
                    }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow
                        key={row.question}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.question}
                        </TableCell>
                        <TableCell align="right">{row.answer}</TableCell>
                        <TableCell align="right">{moment(row.date).format('MM-DD-YYYY')}</TableCell>
                        <TableCell align="right">{row.category === undefined ?? null ? "---" : row.category}</TableCell>
              <TableCell align="right">{row.dob === undefined ?? null ? "---" : moment(row.dob).format('MM-DD-YYYY')}</TableCell>
              <TableCell align="right">{row.dod === undefined ?? null ? "---" : moment(row.dod).format('MM-DD-YYYY')}</TableCell>
                        <TableCell align="right">{row.correct}</TableCell>
                        <TableCell align="right">{row.incorrect}</TableCell>
                        <TableCell align="right"><Button sm={{ mr: 2 }} style={{color:'#676767'}} onClick={() => { handleOpen(row) }}>Edit</Button> <Button style={{color:'#676767'}} onClick={() => { handleDelete(row.id) }}>Delete</Button></TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                  {/* <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter> */}
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <form className="formfortable" >
            <div>
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'flex' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.1rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  justifyContent: 'center'
                }}

              >
                Edit Information
              </Typography>
            </div>

            <TextField
       
          id="Category"
          label="Category"
          type="text"
        
          value={category}
          sx={{ mt: 2, mb: 2 }}
          onChange={(e) => setCategory(e.target.value)} 
        />
            <TextField

              id="question"
              label="Question"
              type="text"
              value={question}
              sx={{ mb: 2 }}
              onChange={(e) => setQuestion(e.target.value)}
            />

            <FormControl fullWidth>
              <InputLabel id="answer">Answer</InputLabel>
              <Select
                labelId="answer"
                id="demo-simple-select"
                value={answer}
                label="Answer"
                sx={{ mb: 2 }}
                onChange={(e) => setAnswer(e.target.value)}

              >
                <MenuItem value={"Dead"}>Dead</MenuItem>
                <MenuItem value={"Alive"}>Alive</MenuItem>

              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>

          <DesktopDatePicker
            label="Date of Birth"
            inputFormat="MM-dd-yyyy"
            value={value2}
            onChange={handleChangebirth}
            sx={{  mb: 2 }}
            renderInput={(params) => <TextField {...params} />}
            />

            <DesktopDatePicker
            label="Date of Death"
            inputFormat="MM-dd-yyyy"
            value={value1}
            onChange={handleChangedead}
            sx={{  mb: 2 }}
            renderInput={(params) => <TextField {...params} />}
            />

          </Stack>
        </LocalizationProvider>
            <Button type="button"
              
              className="form__custom-button"
              variant="contained"
              onClick={HandleSumbitEdit}
              style={{background:'#676767'}}
               >
              Edit Info
            </Button>

          </form>

        </Box>
      </Modal>
    </>
  );
}