import React, { useState,useEffect } from "react";
import "./login.css";
import TextField from '@mui/material/TextField';
// import Button from "./components/Button";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Header from '../Header'
// import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import FormHelperText from '@mui/material/FormHelperText';
import {useNavigate} from 'react-router-dom'
import Flatpickr from 'react-flatpickr'
// import '@styles/react/libs/flatpickr/flatpickr.scss'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';

 import "flatpickr/dist/themes/light.css"


const Adminmain = () => {
  const history = useNavigate()
  const [picker, setPicker] = useState(new Date())
  const [date, setDate] = useState(new Date());
  const [value, setValue] = React.useState(new Date());
  const [category, setCategory] = useState('');
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues
  } = useForm();
  const HandleQuestion = () => {
    history(`/adminmain/${date}/${category}`)
  }

  const handleChange = (newValue) => {
    setValue(newValue)
    setDate(moment(newValue).format('YYYY-MM-DD'))
  };

  useEffect(() => {

    setDate(moment(new Date()).format('YYYY-MM-DD'));
  }, [])

  console.log("date",date)


  return (
    <div className="">
      <Header />
      <div style={{display:"flex", justifyContent:'end',marginTop:'10px',marginRight:'10px'}}> 
      <Button type="button" variant="contained" color="primary" className="form__custom-button" onClick={() => history('/admintable')}>
        Show Question
        </Button>
      </div>
      <form className="form" onSubmit={handleSubmit(HandleQuestion)}>
        <div>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
              justifyContent: 'center'
            }}
             >
              Select Date
          </Typography>
        </div>
        {/* <Flatpickr  
              id='Date'
              name='Date'
               options={{
                dateFormat: "mm-dd-yyyy",
                altInput: true
              }} 
              style={{ backgroundColor: 'white' }} 
              value={picker} 
              onChange={date => setPicker(date)}
             
               required  
              /> */}

<LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>

            <DesktopDatePicker
            label="Date desktop"
            inputFormat="MM-dd-yyyy"
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
            />

          </Stack>
        </LocalizationProvider>


        <TextField
        {...register("Category-main ", { required: true })}
          id="Category_main"
          label="Category"
          type="text" 
          error={Boolean(errors.category )}
          // required
          helperText={errors.category  && "Category  is required"}
          value={category}
          sx={{ mt: 2, mb: 2 }}
          onChange={(e) => setCategory(e.target.value)} 
        />

        {/* <TextField
        {...register("date", { required: true })}
          id="date"
          // format="mm-dd-yyyy"
          error={Boolean(errors.date)}
          helperText={errors.date && "date is required"}
          type="date"
          value={date}
          sx={{ mt: 2, mb: 2 }}
          onChange={(e) => setDate(e.target.value)}
        /> */}
        <Button type="submit" variant="contained"  className="form__custom-button" style={{background:'#676767'}}>
          Submit
        </Button>

      </form>
    </div>
  );
  //   }
}
export default Adminmain;
