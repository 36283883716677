import React, { useState } from "react";
import "./login.css";
import TextField from '@mui/material/TextField';
// import Button from "./components/Button";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';


 const Adminlogin = () => {
  const history = useNavigate()
    const [email, setEmail] = useState('')
    const [password,setPassword] = useState('')

const HandleSubmit= (e) => {
  console.log("admin")
  // e.prevenDefulte()
  if(email === 'admin' && password ==='123456'){
  history('/adminselectdate')
  NotificationManager.success('Admin Login Successfully', 'Welcome Admin')
  }else{
    NotificationManager.error('Your credentials are wrong.')
  }
}


    return (
      <div className="App">
        <form className="form" onSubmit={HandleSubmit}>
         <div>
         <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'flex' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                justifyContent:'center'
                            }}
                        >
                            LOGIN 
                        </Typography>
         </div>
        <TextField
          id="outlined-password-input"
          label="Username"
          type="text"
          autoComplete="current-password"
          sx={{ mt: 2, mb: 2 }}
          onChange={(e) => setEmail(e.target.value)}
        />
          <TextField
          id="outlined-password-input"
          label="Password"
          type="password"
          autoComplete="current-password"
          sx={{ mt: 0, mb: 2 }}
          onChange={(e) => setPassword(e.target.value)}
        />

          <Button type="button" variant="contained" className="form__custom-button" style={{background:'#676767'}} onClick={HandleSubmit}>
            Log in
          </Button>
        
        </form>
      </div>
    );
//   }
} 
export default Adminlogin;
