import React, { useState, useEffect, useRef } from 'react';
import Header from '../component/Header';
import Table from 'react-bootstrap/Table';
import axios from 'axios';

import copy from 'react-copy-to-clipboard';


import { NotificationContainer, NotificationManager } from 'react-notifications';

export default function ViewCumulativeScore() {
   
  const [result, setResult] = useState([])
  const [correctanswer, setCorrectanswer] = useState([])
  const [WrongCorrectanswer, setWrongCorrectanswer] = useState([])
  
  const [copySuccess, setCopySuccess] = useState('');

  // your function to copy here
  
    const copyToClipBoard = async copyMe => {
      try {
        await navigator.clipboard.writeText(copyMe);
        setCopySuccess('Copied!');
      } catch (err) {
        setCopySuccess('Failed to copy!');
      }
    };


    const [bttnText, setBttnText] = useState("Share Score");
  const couponRef = useRef();

  const copyCode = () => {
    // just a safety mechanism to make sure that
    // the references found the DOM elmenets button and coupon
    // before trying to use them
    if (!couponRef.current) return;


    navigator.clipboard
      // again doesn't make sense to use here
      // couponRef.current.innerHTML
      // since you got access to post.coupon
      .writeText(couponRef.current.innerHTML)
      .then(() => {
        setBttnText("COPIED");
        setTimeout(function () {
          setBttnText("COPY CODE");
        }, 3000);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
   const Getdata = () => {


    var stepsData = JSON.parse(localStorage.getItem('questionDataList')); 

    
   
      
    
        
        const unique = [...new Map(stepsData.map((m) => [m.Qid, m])).values()];


        const newdata = unique.filter((ele, index) => ele.useranswer === ele.answer)


        const wrongdata = unique.filter((ele, index) => ele.useranswer != ele.answer)


        setCorrectanswer(newdata.length)


        setWrongCorrectanswer(wrongdata.length)
        setResult(unique)
   
       
          



          
   }
  
  
   useEffect(() => {
    Getdata();
  
    
    }, [])
  
    
      

  return (
    <div>
        <Header />
        <div className="innderstepper"><div className="table-responsive"><strong>Your Cumulative Score</strong>
        <a href="#"  >
         
    
  </a>
</div>

<div className="coupon-row" >
              <span ref={couponRef} style={{display:'none'}}> DeadRLive: Play it on https://deadr.live
        
        Correct: {correctanswer} Wrong: {WrongCorrectanswer}</span>

              <button onClick={copyCode}>{bttnText}</button>
            </div>


    
         
      


<Table responsive>
      <thead>
        <tr>
          <th className='text-center'>Correct Answers</th>
          <th className='text-center'>Wrong Answers</th>
          
        
        </tr>
      </thead>
      <tbody>
 <tr>
          
        <td className='text-center'>{correctanswer}</td>
          
          <td className='text-center'>{WrongCorrectanswer}</td>
         
        </tr>
         
       

        </tbody>
        </Table>


       <Table responsive>
      <thead>
        <tr>
          <th className='text-center'>S.No.</th>
          <th className='text-center'>Date</th>
          <th className='text-center'>Questions</th>
          <th className='text-center'>Your Answer</th>
          <th className='text-center'>Correct Answer</th>
        
        </tr>
      </thead>
      <tbody>

      {result.map((row,index) => {
            return (
                <tr>
          <td className='text-center '>{index + 1}</td>
          <td className='text-center'>{row.date}</td>
          <td className='text-center'>{row.question}</td>
          {row.useranswer === row.answer ? <td className={`text-center`} style={{color:'green'}}>{row.useranswer}</td> : <td className={`text-center text-red`}  style={{color:'red'}}>{row.useranswer}</td>}
          
          <td className='text-center'>{row.answer}</td>
         
        </tr>
            )
        })
            }

        </tbody>
        </Table>
        
       </div>
    </div>
  );
}



