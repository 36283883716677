import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import './stepper.css'
import axios from 'axios'
import Amchart from '../Amchart/amchart'
import {BASE_URL} from '../../baseURl'
// import {FontAwesomeIcon}  from 'font-awesome'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import Paper from '@mui/material/Paper';

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad', 'Create an ad', 'Create an ad', 'Create an ad', 'Create an ad', 'Create an ad', 'Create an ad', 'Create an ad'];

const question = ["What is the java", "What is the javaScript", "What is the React js", "What is the MongoDB", "What is the Php", "What is the C++", "What is the Solidity", "What is the Go", "What is the C", "What is the python"]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius:' 25px',
    boxShadow: 24,
    p: 4,
  };


  const stylesecond = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    // minWidth: 400,
    bgcolor: 'background.paper',
    borderRadius:' 25px',
    boxShadow: 24,
    overFlow:'scroll',
    p: 4,
  };
  
 
export default function HorizontalNonLinearStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({})
    const [data, setData] = useState([])
    const [question, setQuestion] = useState([])
    const [questionanswer, setQuestionanswer] = useState([])
    const [open, setOpen] = React.useState(false);
    const [opensecond, setOpensecond] = React.useState(false);
    const [result, setResult] = useState([])
    const [Correctanswer, setCorrectanswer] = useState(0)
    const [unCorrectanswer, setUnCorrectanswer] = useState(0)
    const [storecorrect,setCorreect] = useState([])
    const [storeincorrect,setIncorreect] = useState([])
    const [category, setCategory] = useState([]);
    const [ipDetails, setIpDetails] = useState([]);
    const [today, setToday] = useState([]);
    const [localIp, setLocalIp] = useState([]);
    const [localdate, setLocalToday] = useState([]);
    const [show, setShow] = useState(false);
    const [cumulativeResult , setcumulativeResult] = useState(JSON.parse((localStorage.getItem('questionData'))))
    const handlesecondOpen = () => {
        setOpen(false)
        setOpensecond(true)
    }

 

    const handlesecondClose = () => {
        setOpensecond(false)
        // window.location.reload(); 
    }




  const handleOpen = (ele) => {
   let init = 0;
   init = init + ele
   setUnCorrectanswer(0)
   setCorrectanswer(0)
   if(init === 1) {
    let array3 = questionanswer.reduce((arr, e) => {
        arr.push(Object.assign({}, e, data.find(a => a.question == e.question)))
        return arr;
      }, [])

      array3.map((ele,index) => {
        if(ele.answer == ele.useranswer ){
            ele.correct =ele.correct +1
            axios.patch(`${BASE_URL}/question/${ele.id}`,{correct :ele.correct}).then((res) => {
                setCorreect((older) => {
                    return [...older ,ele.correct]
                })
            }).catch((err) => {
                console.log(err)
            })
            
            setCorrectanswer((older) => {
                return older + 1
            })
        }else {
            ele.incorrect =ele.incorrect + 1
            axios.patch(`${BASE_URL}/question/${ele.id}`,{incorrect :ele.incorrect}).then((res) => {
                setIncorreect((older) => {
                    return [...older ,ele.incorrect]
                })
            }).catch((err) => {
                console.log(err)
            })
            setUnCorrectanswer((older) => {
                return older + 1
            })
        }
         
      })
      setResult(array3)


      
    setOpen(true)
   }else{
    setOpen(true)
   }
    
}

console.log("result", result)
// console.log("storeincorrect", storeincorrect)
// console.log("storecorrect", storecorrect)

const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    setResult([])
    setCorrectanswer(0)
    setUnCorrectanswer(0)
    setIncorreect([])
    setIncorreect([])
};

  const handleClose = () => {
    setOpen(false)
    // window.location.reload();    // handleReset()
}

const Getdata = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();

        today = `${yyyy}-${mm}-${dd}`
        const newarr = []
        // console.log("today", today)
        axios.get(`${BASE_URL}/question`).then((response) => {
            // console.log("response", response)
            const newdata = response.data.filter((ele, index) => ele.date === today)
            console.log("data", newdata[0].category)

            setData(newdata)
            newdata.map((ele, index) => {
                newarr.push(ele.question)
                // console.log("ele", ele)
            })
           setCategory(newdata[0].category)
            setQuestion(newarr);
           
        }).catch((error) => {
            NotificationManager.success('No Questions Available')
        })


        
    }
    useEffect(() => {
      
        
     axios.get('https://geolocation-db.com/json/').then((res) => {

            console.log(res.data.IPv4);
            setIpDetails(res.data.IPv4);
         
        });
        let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    today = `${yyyy}-${mm}-${dd}`
    setToday(today);

  
    var stepsData = JSON.parse(localStorage.getItem('stepsData')); 
    if(stepsData != null){
    setLocalIp(stepsData.ipAddress);
    setLocalToday(stepsData.todayData);
    }
      }, [])
    const totalSteps = () => {
        return data.length;
    };

    const completedSteps = () => {
     return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
       
    };

    const allStepsCompleted = () => {

       if(completedSteps() === totalSteps()){

      
       let today = new Date();
       let dd = String(today.getDate()).padStart(2, '0');
       let mm = String(today.getMonth() + 1).padStart(2, '0');
       let yyyy = today.getFullYear();

       let ipaddress = ipDetails;

       today = `${yyyy}-${mm}-${dd}`
       var obj = {
        "ipAddress": ipDetails,
         "todayData": today
};

var stepsData = JSON.parse(localStorage.getItem('stepsData')); 


//console.log("stepData" + stepsData.ipAddress);
if(stepsData == null){
    localStorage.setItem("stepsData", JSON.stringify(obj));
}else{
   
if(stepsData.todayData != today && stepsData.ipAddress != ipDetails){
   

    localStorage.setItem("stepsData", JSON.stringify(obj));
}else if(stepsData.todayData < today){

    localStorage.setItem("stepsData", JSON.stringify(obj));
}
}

}
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                data.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();

       
    }
    
    const handleAnswer = (question,answer) => {
        // let newarr = []
     

        // Object.entries({"question": question,"answer":answer}).forEach(([key,value]) => { obj[key] = value })
        // newarr.push([...newarr,{"question": question,"answer":answer}])
        setQuestionanswer((newarr) => {
            return [...newarr,{"question": question,"useranswer":answer}]
        } )
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };
    //console.log('questionanswer',questionanswer);
   

    var questionData = JSON.parse(localStorage.getItem('questionData')); 


    const Questionrows = [];
    for (let i = 0; i < result.length; i++) {


    let  userData = { "question": result[i].question,
    "answer" : result[i].answer,
         "date" : result[i].date,

"Qid" : result[i].id,

"useranswer" :  result[i].useranswer,
"userIpAddress" :ipDetails
};


 
Questionrows.push(userData);
    }

        
if(Questionrows!=""){
    localStorage.setItem("questionData",JSON.stringify(Questionrows));



   
}else{
   
  //  localStorage.setItem("questionData", JSON.stringify(Questionrows));
}


const cumulativeData = [];
    for (let i = 0; i < result.length; i++) {
    let  rowData = { "question": result[i].question,
    "answer" : result[i].answer,
         "date" : result[i].date,

"Qid" : result[i].id,

"useranswer" :  result[i].useranswer,
"userIpAddress" :ipDetails
};

cumulativeData.push(rowData);
};
   

    const getCarStorage = localStorage.getItem('questionDataList')
    if(getCarStorage !="" ){
        const  itemsList = JSON.parse(localStorage.getItem('questionDataList'))

        console.log('itemsList');
        console.log(itemsList);
  
        console.log('itemsList end');

        console.log('cumulativeData');
        console.log(cumulativeData);
  
        console.log('cumulativeData end');

        
        if(cumulativeData != "" && itemsList != null){
    var newArray =   itemsList.concat(cumulativeData)
       
     
     
        localStorage.setItem('questionDataList', JSON.stringify(newArray))
        }else{
            if(cumulativeData != "" && itemsList == null){
            localStorage.setItem('questionDataList', JSON.stringify(cumulativeData))
            }
        }
    }else{
       
    }


   
    useEffect(() => {
        Getdata();
      
        
    }, [])

    
    return (

      

        <div className='steppperclass'>

<div style={{display:"flex", justifyContent:'end',marginTop:'10px',marginRight:'10px'}}> 
<a  href='/viewcumulativescore' className="cumulative_score-button">
             View Cumulative Score
           </a>
      </div>

      <div className='innderstepper'>

<strong>{category}</strong>



        
            
   


            { localdate == today && localIp == ipDetails ?   <Table responsive>
      <thead>
        <tr>
          <th className='text-center'>S.No.</th>
          <th className='text-center'>Questions</th>
          <th className='text-center'>Your Answer</th>
          <th className='text-center'>Correct Answer</th>
        </tr>
      </thead>
      <tbody>

        {cumulativeResult.map((row,index) => {
            return (
                <tr>
          <td className='text-center '>{index + 1}</td>
          <td className='text-center'>{row.question}</td>
          {row.useranswer === row.answer ? <td className={`text-center`} style={{color:'green'}}>{row.useranswer}</td> : <td className={`text-center text-red`}  style={{color:'red'}}>{row.useranswer}</td>}
          
          <td className='text-center'>{row.answer}</td>
        </tr>
            )
        })
            }
      </tbody>
    </Table> : <div className='fieldsetpath'>
                {data.length !== 0 ? <Box sx={{ width: '100%' }}>
                    <Stepper nonLinear activeStep={activeStep} >
                        {data.map((label, index) => (
                            <Step key={label.id} completed={completed[index]}>
                                <StepButton sx={{
                                    display: {
                                        xs: 'flex', md: 'flex'
                                    },
                                    paddingRight: { xs: '0px' },


                                }}
                                
                                    color="inherit" onClick={handleStep(index)}>
                                    {/* {label} */}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <div className='questioncard'>
                    

                        
                        {allStepsCompleted() ? (


                            
                            <React.Fragment>
                               <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2,justifyContent:'center' }}>
                                    {/* <Box sx={{ flex: '1 1 auto' }} /> */}
                                    <Button variant="contained" style={{background:'#676767'}} onClick={() => handleOpen(1)}>Show Result</Button>
                                </Box>
                                {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box> */}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography
                                    variant="h4"
                                    sx={{ mt: 5, mb: 3 }}
                                >

                                    {/* Q:-{activeStep + 1}  */} {question[activeStep] ? question[activeStep].toLowerCase()
                                        .split(' ')
                                        .map(word => {
                                            return word.charAt(0).toUpperCase() + word.slice(1);
                                        }).join(' ') : ""}{""}
                                </Typography>
                                <div>
                                    <Button variant="contained" style={{ marginRight: '5px', background:'#676767' }} onClick={() => handleAnswer(question[activeStep],'Dead')}>Dead</Button>
                                    <Button variant="contained" style={{background:'#676767'}}  onClick={() => handleAnswer(question[activeStep],'Alive')}>Alive</Button>
                                </div>

                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                        style={{color:'#676767'}}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button
                                    style={{color:'#676767'}}
                                     onClick={handleNext} sx={{ mr: 1 }}>
                                        Next
                                    </Button>
                                    {/* {activeStep !== data.length &&
                                        (completed[activeStep] ? (
                                            <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                Step {activeStep + 1} already completed
                                            </Typography>
                                        ) : (
                                            <Button onClick={handleComplete}>
                                                {completedSteps() === totalSteps() - 1
                                                    ? 'Finish'
                                                    : 'Complete Step'}
                                            </Button>
                                        ))} */}
                                </Box>
                            </React.Fragment>
                        )}
                    </div>
                </Box> : null}
                {/* First Modal Open */}
                <Modal 
                show={open} 
                onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
                >
        {(Correctanswer == Correctanswer + unCorrectanswer) ? <Modal.Body >



           
            <h3 className='text-center'>Your Score</h3>
            <p className='text-center'>{/* You've completed the Quiz!  */}

            
          
           You Genius, you got them all! 
           {/* You got only  out of  */}</p>

           <Amchart Correctanswer={Correctanswer} unCorrectanswer={unCorrectanswer} /> 
               <div className='d-flex justify-content-center mt-3' style={{display:'flex',justifyContent:"center", marginTop:'15px'}}>
                <Button variant="contained" style={{background:'#676767'}}   onClick={handlesecondOpen}>View Details</Button>
               </div>
            </Modal.Body> : (Correctanswer == 0) ? <Modal.Body >
            <h3 className='text-center'>Your Score</h3>
            <p className='text-center'>{/* You've completed the Quiz!  */}
           
            Whomp Whomp, you did not get any correct
           {/* You got only  out of  */}</p>
      <Amchart Correctanswer={Correctanswer} unCorrectanswer={unCorrectanswer} /> 
               <div className='d-flex justify-content-center mt-3' style={{display:'flex',justifyContent:"center", marginTop:'15px'}}>
                <Button variant="contained" style={{background:'#676767'}}   onClick={handlesecondOpen}>View Details</Button>
               </div>
            </Modal.Body> : <Modal.Body >
            <h3 className='text-center'>Your Score</h3>
            <p className='text-center'>{/* You've completed the Quiz!  */}
           
           You've completed the Quiz! 
           {setResult}
           You only got {Correctanswer} correct out of {Correctanswer + unCorrectanswer}.
           {/* You got only  out of  */}</p>
      <Amchart Correctanswer={Correctanswer} unCorrectanswer={unCorrectanswer} /> 
               <div className='d-flex justify-content-center mt-3' style={{display:'flex',justifyContent:"center", marginTop:'15px'}}>
                <Button variant="contained" style={{background:'#676767'}}   onClick={handlesecondOpen}>View Details</Button>
               </div>
            </Modal.Body>
            
            
            }

       
        
        
      </Modal>
{/*END First Modal Open */}
{/* Second Modal Open */}
<Modal 
show={opensecond} 
onHide={handlesecondClose}
size="lg"
aria-labelledby="contained-modal-title-vcenter"
      centered
>
       
        <Modal.Body>
        <h3 className='text-center'>Your Score</h3>

        <Table responsive>
      <thead>
        <tr>
          <th className='text-center'>S.No.</th>
          <th className='text-center'>Questions</th>
          <th className='text-center'>Your Answer</th>
          <th className='text-center'>Correct Answer</th>
        </tr>
      </thead>
      <tbody>

  
      {result.map((row,index) => {
            return (
                <tr>
          <td className='text-center '>{index + 1}</td>
          <td className='text-center'>{row.question}</td>
          {row.useranswer === row.answer ? <td className={`text-center`} style={{color:'green'}}>{row.useranswer}</td> : <td className={`text-center text-red`}  style={{color:'red'}}>{row.useranswer}</td>}
          
          <td className='text-center'>{row.answer}</td>
        </tr>
            )
        })
            }
          
   

      </tbody>
    </Table>
           </Modal.Body>
       
      </Modal>


     
      {/*END Second Modal Open */}
            </div>

        }
</div>
        </div>
    );
}



