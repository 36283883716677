import { Helmet, HelmetProvider } from 'react-helmet-async';

const custom = window.custom;
const HeadTags = (props) => {
  
  const {
    title = "Portfolio - your name",
    metaDescription = "defaul tdescription",
  } = props;
  return (
    <HelmetProvider>
    <Helmet>
    <meta charset="utf-8" />
<meta property="og:url"                content="http://192.168.29.231:3001/" />
<meta property="og:type"               content="article" />
<meta property="og:title"              content="When Great Minds Don’t Think Alike" />
<meta property="og:description"        content="How much does culture influence creative thinking?" />
<meta property="og:image"              content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />

<meta property="fb:app_id" content="698780458369569" />

    </Helmet>
    </HelmetProvider>
  );



  };
  export default HeadTags