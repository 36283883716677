import logo from './logo.svg';
import './App.css';
import Login from './component/Admindashboard/login'
import Index from './component/index'
import Adminmain from './component/Admindashboard/AdminMain'
import AdminTable from './component/Admindashboard/Table'
import Adminselectdate from './component/Admindashboard/Adminselectdate'
import ViewCumulativeScore from './component/viewcumulativescore'

import {
 
  Routes,
  Route,
  Link
} from "react-router-dom"

function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/adminlogin" element={<Login />} />
      <Route path="/adminmain/:id/:category" element={<Adminmain />} />
      <Route path="/admintable" element={<AdminTable />} />
      <Route path="/adminselectdate" element={<Adminselectdate />} />
      <Route path="/viewcumulativescore" element={<ViewCumulativeScore />} />

      </Routes>
    </div>
  );
}

export default App;
