import React, { useState,useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import "./login.css";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from '../Header'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios'
import { Container } from '@mui/material';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import FormHelperText from '@mui/material/FormHelperText';
import {useNavigate,useParams} from 'react-router-dom'
import {BASE_URL} from '../../baseURl'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';

export default function EnhancedTable() {
  const history = useNavigate()
  const dateparams = useParams()
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [dob, setDOB] = useState(new Date());
  const [dod, setDOD] = useState(new Date());
  const [currentdatedata, setCurrentdatedata] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [category, setCategory] = useState('');
  const [value, setValue] = React.useState(new Date());
  const [value1, setValue1] = React.useState(new Date());


  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues
  } = useForm();
  const HandleQuestion = () => {
    
    const data ={
      category,
      question,
      answer,
      date :dateparams.id,
      dob,
      dod,
      correct: 0,
      incorrect: 0
    }
 
    axios.post(`${BASE_URL}/question`,data).then((response) => {
     /// console.log("response", response)
      NotificationManager.success('Admin Add Question Successfully')
      setCategory('')
      setQuestion('')
      setAnswer('')
      setValue1(new Date())
      setValue(new Date())
      Getdata()
    }).catch((error) => {
      NotificationManager.success('Internet Server Issue')
    })
    axios.get(`${BASE_URL}/question`).then((response) => {
      console.log("responseAll", response)
      const newdata = response.data.filter((ele, index) => ele.date === dateparams.id)
      console.log("dataCurrentDate", newdata);

     console.log(newdata.length);
      setCurrentdatedata(newdata);
      Checkbuttondisabled(newdata);
      for (var i = 0; i < newdata.length; i++) {
       // console.log("tcttc"+newdata);
        
       const dataPOst =  {
        question: newdata[i].question,
        category : category,
        answer: newdata[i].answer,
        date: newdata[i].date,
        dob : newdata[i].dob,
        dod : newdata[i].dod,
        correct: 0,
        incorrect: 0
        
      };
       axios.patch(`${BASE_URL}/question/${newdata[i].id}`, dataPOst).then((response) => {
        });
    


        
    }
  }).catch((error) => {
      NotificationManager.success('Internet Server Issue')
  })
  }

// console.log("date",dateparams.id)
  const Getdata = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    today = `${yyyy}-${mm}-${dd}`
    const newarr = []
    console.log("today", today)
    axios.get(`${BASE_URL}/question`).then((response) => {
        console.log("responseAll", response)
        const newdata = response.data.filter((ele, index) => ele.date === dateparams.id)
        console.log("dataCurrentDate", newdata);

       console.log(newdata.length);
        setCurrentdatedata(newdata);
        Checkbuttondisabled(newdata);
        for (var i = 0; i < newdata.length; i++) {
          console.log("tcttc"+newdata[i].id);
          if(document.getElementById("Category").value !="") {
         const dataPOst =  {
          question: newdata[i].question,
          category : document.getElementById("Category").value,
          answer: newdata[i].answer,
          date: newdata[i].date,
          dob : newdata[i].dob,
          dod : newdata[i].dod,
          correct: 0,
          incorrect: 0
          
        };
         axios.patch(`${BASE_URL}/question/${newdata[i].id}`, dataPOst).then((response) => {
          });
        }


          
      }
    }).catch((error) => {
        NotificationManager.success('Internet Server Issue')
    })
}
 

//console.log("getdata", currentdatedata)

const Checkbuttondisabled = (newdata) => {
if(newdata.length === 10) {
  setDisabled(true)
}else {
  setDisabled(false)
}
}
useEffect(() => {
  Getdata()
}, [])

const handleChangebirth = (newValue) => {
  setValue(newValue)
  setDOB(moment(newValue).format('YYYY-MM-DD'))
}

const handleChangedead = (newValue) => {
  setValue1(newValue)
  setDOD(moment(newValue).format('YYYY-MM-DD'))
};


  return (
    <>
    
    <div style={{marginBottom:'50px'}}>
      <Header />
      <div style={{display:"flex", justifyContent:'end',marginTop:'10px',marginRight:'10px'}}> 
      <Button type="button" variant="contained"  className="form__custom-button"  
      style={{background:'#676767'}} onClick={() => history('/admintable')}>
        Show Question
        </Button>
      </div>
      <form className="form" onSubmit={handleSubmit(HandleQuestion)}>
        <div>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
              justifyContent: 'center'
            }}
            
          >
            Question
          </Typography>
        </div>
        <TextField
        {...register("Category ", { required: true })}
          id="Category" style={{display:'none'}}
          label=""
          type="hidden"
          error={Boolean(errors.category )}
          // required
          helperText={errors.category  && "Category  is required"}
          value={ dateparams.category }
          sx={{ mt: 2, mb: 2 }}
          onChange={(e) => setCategory(e.target.value)} 
        />

        <TextField
        {...register("question", { required: true })}
          id="question"
          label="Question"
          type="text"
          error={Boolean(errors.question)}
          // required
          helperText={errors.question && "question is required"}
          value={question}
          sx={{  mb: 2 }}
          onChange={(e) => setQuestion(e.target.value)} 
        />
        
        <FormControl fullWidth>
          <InputLabel id="answer">Answer</InputLabel>
          <Select
           {...register("answer", { required: true })}
            labelId="answer"
            id="demo-simple-select"
            error={Boolean(errors.answer)}
            // required
            helperText={errors.answer && "answer is required"}
            value={answer}
            label="Answer"
            sx={{ mb: 2 }}
            onChange={(e) => setAnswer(e.target.value)}
           
          >
            <MenuItem value={"Dead"}>Dead</MenuItem>
            <MenuItem value={"Alive"}>Alive</MenuItem>

          </Select>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>

          <DesktopDatePicker
            label="Date of Birth"
            inputFormat="MM-dd-yyyy"
            value={value}
            onChange={handleChangebirth}
            sx={{  mb: 2 }}
            renderInput={(params) => <TextField {...params} />}
            />

            <DesktopDatePicker
            label="Date of Death"
            inputFormat="MM-dd-yyyy"
            value={value1}
            onChange={handleChangedead}
            sx={{  mb: 2 }}
            renderInput={(params) => <TextField {...params} />}
            />

          </Stack>
        </LocalizationProvider>

          <FormHelperText error={Boolean(errors.answer)}>{errors.answer && "answer is required"}</FormHelperText>
        </FormControl> 
        <Button type="submit"  className="form__custom-button" variant="contained" style={{background:'#676767'}} disabled={disabled}>
          {disabled ? "Current Date Question Completed" : 'Save'}
        </Button>

      </form>
    </div>
    <Container maxWidth="xl" style={{marginBottom:'40px'}} ><div>
    <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
              justifyContent: 'center'
            }}
            
          >
            Select Date Data
          </Typography>



          { dateparams.category }
    </div>
   {currentdatedata.length !== 0 ? <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell align="right">Answer</TableCell>
            <TableCell align="right">Date</TableCell>
         {/* <TableCell align="right">Category</TableCell> */}
            <TableCell align="right"> Date of Birth
</TableCell>
            <TableCell align="right">Date of Death
</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentdatedata.map((row) => (
            <TableRow
              key={row.question}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.question}
              </TableCell>
              <TableCell align="right">{row.answer}</TableCell>
              <TableCell align="right">{moment(row.date).format('MM-DD-YYYY')}</TableCell>
             {/* <TableCell align="right">{row.category === undefined ?? null ? "---" : row.category}</TableCell> */}
              <TableCell align="right">{row.dob === undefined ?? null ? "---" : moment(row.dob).format('MM-DD-YYYY')}</TableCell>
              <TableCell align="right">{row.dod === undefined ?? null ? "---" : moment(row.dod).format('MM-DD-YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> : <Typography
            
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
              justifyContent: 'center'
            }}
            
          >
            There Are No Data for Current Date
          </Typography>} 
    </Container>
    </>
  );
}
