import React from 'react'
import Header from '../component/Header'
import Stepper from './Stepper/stepper'

function index() {
  return (
    <div>
        <Header />
        <Stepper />
    </div>
  )
}

export default index