

import React, { useEffect } from 'react'
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"


export default function AmCharts(props) {
    const {unCorrectanswer ,Correctanswer} = props
    console.log("dataamchart",unCorrectanswer,Correctanswer)

    useEffect(() => {
        am4core.ready(function () {

            var chart = am4core.create("chartdiv", am4charts.PieChart);

            // Add data
            chart.data = [{
              "country": "Correct Answers",
              "litres": Correctanswer
            }, {
              "country": "Wrong Answers",
              "litres": unCorrectanswer
            }];
            
            // Add and configure Series
            var pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "litres";
            pieSeries.dataFields.category = "country";
            
            // Let's cut a hole in our Pie chart the size of 40% the radius
            chart.innerRadius = am4core.percent(40);
            
            // Disable ticks and labels
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;
            
            // Disable tooltips
            pieSeries.slices.template.tooltipText = "";
            
            // Add a legend
            chart.legend = new am4charts.Legend();
        })

        }, [props])
        return (
            <div className='container'  >
                <div id="chartdiv" style={{ height: '400px', width: '100%' }}></div>
            </div>
        )
    }


